<template>
    <div class="first_reserve_popup" id="firstReservePopup" v-show="popupVisible">
        <div class="first_reserve_wrap">
            <div class="first_tit">
                <h4>첫방문 간편예약</h4>
                <a href="javascript:;" @click="hidePopup">
                    <span></span>
                    <span></span>
                </a>
            </div>

            <div class="contents first_desc">
                <div class="text-box type1 info-text">
                    <p>간편예약은 처음오시는 고객의 편의를 위하여 전문 상담원이 전화를 통해 진료예약을<br>
                        도와 드리는 서비스입니다.</p>
                    <h3>상담시간</h3>
                    <p>평일 : 08:30 ~ 16:30, 토/공휴일 휴무</p>
                </div>
            </div>

            <div class="contents text-box type2 first_info_box">
                <h3 class="text-box__tit">개인정보 수집 및 이용목적에 대한 동의
                    <span class="c-red">(필수)</span>
                </h3>
                <div class="text-box__wrap">
                  <h3>1. 개인정보의 수집 이용목적</h3>
                  <p>첫 방문 환자가 간편하게 예약할 수 있도록 돕기 위한 용도</p>
                  <h3>2. 수집하려는 개인정보의 항목</h3>
                  <p>이름, 전화번호</p>
                  <h3>3. 개인정보의 보유 및 이용기간</h3>
                  <p>개인정보의 보유기간은 3년이며. 이후 삭제됩니다.</p>
                  <h3>4.동의를 거부할 권리 / 동의거부에 따른 안내</h3>
                  <p>개인정보 수집 및 이용에 대해 거부할 권리가 있으며 다만, 이를 거부할 시에는 첫방문 간편예약 서비스 이용이 제한됩니다.</p>
              </div>
            </div>
            <div class="agree-input">
                <label for="agree-y" class="agree-input__item">
                    <input type="radio" id="agree-y" class="agree-input__radio" name="agree" v-model="agree" value="Y">
                    <span class="icon"></span> 동의합니다 </label>
                <label for="agree-n" class="agree-input__item">
                    <input type="radio" id="agree-n" class="agree-input__radio" name="agree" v-model="agree" value="N">
                    <span class="icon"></span> 동의하지 않습니다
                </label>
            </div>

            <div class="contents first_input_box" v-if="!$store.state.reserveStore.first">
                <div class="input-form type1">
                    <p class="input-form__top-text">
                        <span class="c-red">* </span> 필수 입력항목
                    </p>
                    <ul class="input-form__list">
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>이름
                                </div>
                                <div class="cont">
                                    <input type="text" class="cont__text-input" v-model="patName">
                                </div>
                            </div>
                        </li>
                        <li class="input-form__item">
                            <div class="input-form__box">
                                <div class="tit">
                                    <span class="c-red">* </span>휴대전화
                                </div>
                                <div class="cont">
                                    <input type="text" class="cont__text-input" v-model="patHpNo" maxlength="11" @input="this.$commonUtils.numberOnly">
                                </div>
                            </div> 
                        </li>
                    </ul>
                </div>
            </div>
            <div class="btn-wrap center first_btn">
                <a href="javascript:;" class="btn" @click="hidePopup">취소</a>
                <a href="javascript:;" class="btn blue" @click="simpleAction">간편예약 신청</a>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props: {
            popupVisible: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                patName:'',
                patHpNo:'',
                agree: 'N',
            }
        },
        methods: {
            simpleAction(){
                if(this.agree == 'N'){
                    alert("개인정보 수집 이용에 동의가 필요합니다.")
                    return
                }

                const flag = this.$store.state.reserveStore.first ? true : false
                if(!flag && (this.patName == '' || this.patHpNo == '')){
                    alert('정보 입력이 필요합니다.');
                    return;
                }

                const hpNo = this.patHpNo ? this.patHpNo : this.$store.state.reserveStore.first.hpNo
                const phone = hpNo.indexOf('-') > -1 ? hpNo.replaceAll('-', '') : hpNo
                this.axios.get(this.api.appointment.simpleReservation, {
                    params: {
                        instNo: 1,
                        hpCd: 'CW',
                        patName: this.patName ? this.patName : this.$store.state.reserveStore.first.name,
                        patHpNo: phone,
                        rsvType: 'W'
                    }
                }).then(response => {
                    const data = response.data;
                    if(data.success == 'N'){
                        alert(data.msg);
                    }else{
                        alert(data.msg);
                        this.hidePopup();
                    }
                });
            },
            emptyText(){
                this.patName = ''
                this.patHpNo = ''
                this.agree = 'N'
                this.$store.commit('setFirst', '')
            },
            hidePopup() {
                this.emptyText();
                if(!this.popupVisible){
                    document.querySelector('#firstReservePopup').style.display = 'none'
                }
                this.$emit('hide-popup');
            },
        },
    }
</script>