<template>
    <div class="login-box">
        <p class="contents sub-text dp2">비밀번호를 찾기 위해서는 아래와 같이 인증이 필요합니다. <br>
            비밀번호 찾기를 위한 인증방법을 선택해주세요.</p>

        <ul class="contents login-box__list password">
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon01.png" alt="이미지"></div>
                <h3 class="tit">본인 인증</h3>
                <p class="desc">본인명의 휴대폰</p>
                <div>
                    <label class="id-find">
                        <span class="input-tit">아이디</span>
                        <input type="text" id="memNiceFindId" v-model="niceId">
                    </label>
                </div>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" @click="this.$refs.NiceIdVue.fnPopup('findPw')">비밀번호 찾기</a>
                </div>
            </li>
            <li class="login-box__item">
                <div class="icon"><img src="@/assets/resource/icon/login-icon02.png" alt="이미지"></div>
                <h3 class="tit">아이핀 인증</h3>
                <p class="desc">아이핀 ID/PW</p>
                <div>
                    <label class="id-find">
                        <span class="input-tit">아이디</span>
                        <input type="text" id="memIpinFindId" v-model="ipinId">
                    </label>
                </div>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" @click="this.$refs.NiceIdVue.popIpinAuth('findPw')">비밀번호 찾기</a>
                </div>
            </li>
            <li class="login-box__item input">
                <h3 class="tit">휴대전화 인증</h3>
                <div>
                    <label>
                        <span class="input-tit">아이디</span>
                        <input type="text" placeholder="아이디" v-model="pId">
                    </label>
                    <label>
                        <span class="input-tit">이름</span>
                        <input type="text" placeholder="이름" v-model="pName">
                    </label>
                    <label class="login-box__phone">
                        <span class="input-tit">휴대전화</span>
                        <div class="input-text">
                            <input type="text" maxlength='3' v-model="pHp1"><span class="line">-</span>
                            <input type="text" maxlength='4' v-model="pHp2"><span class="line">-</span>
                            <input type="text" maxlength='4' v-model="pHp3">
                        </div>
                    </label>
                </div>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" @click="phoneCert">비밀번호 찾기</a>
                </div>
            </li>
            <li class="login-box__item input">
                <h3 class="tit">이메일 인증</h3>
                <div>
                    <label>
                        <span class="input-tit">아이디</span>
                        <input type="text" placeholder="아이디" v-model="eId">
                    </label>
                    <label>
                        <span class="input-tit">이름</span>
                        <input type="text" placeholder="이름" v-model="eName">
                    </label>
                    <label class="login-box__email">
                        <span class="input-tit">이메일</span>
                        <div class="input-text">
                            <input type="text" v-model="email1"> <span class="line">@</span>
                            <input type="text" v-model="email2">
                        </div>
                    </label>
                </div>
                <div class="btn-wrap center">
                    <a href="javascript:;" class="btn blue" @click="emailCert">비밀번호 찾기</a>
                </div>
            </li>
        </ul>
        <div class="contents">
            <div class="btn-wrap right"><router-link to="/member/idInquiry.do" class="btn dark-gray">아이디 찾기</router-link></div>
        </div>
    </div>
    <NiceIdVue ref="NiceIdVue"></NiceIdVue>
</template>

<script>
import NiceIdVue from '../util/NiceId.vue';
export default {
    components: {
        NiceIdVue
    },
    data() {
        return {
            niceId: '',
            ipinId: '',
            pId: '',
            pName: '',
            pHp1: '',
            pHp2: '',
            pHp3: '',
            eId: '',
            eName: '',
            email1: '',
            email2: ''
        }
    },
    mounted() {
        
    },
    methods: {
        // 휴대전화 인증
        phoneCert() {
            if (!this.pId) {
                alert('아이디를 입력해주세요.');
                return
            }
            if (!this.pName) {
                alert('이름을 입력해주세요.');
                return
            }
            if (!this.pHp1 || !this.pHp2 || !this.pHp3) {
                alert('휴대전화번호를 입력해주세요.');
                return
            }
            const phone = this.pHp1 + '-' + this.pHp2 + '-' + this.pHp3;
            this.axios
                .get(this.api.member.findPasswordPhone, {
                    params: {
                        // hpCd: this.$store.state.hpInfoStore.hpCd,
                        memId: this.pId,
                        memName: this.pName,
                        memHpNo: phone
                    }
                })
                .then(res => {
                    const result = res.data;
                    if (res.data.status) {
                        this.$store.commit('setFind', {'휴대전화번호': result.hpNo.substring(0, 9) + '****'});
                        this.$router.push(`/member/pwResolution.do`);
                    } else {
                        alert(result.errMsg);
                    }
                })
                .catch(err => {
                    alert('가입내역이 존재하지 않습니다.')
                })
        },

        // 이메일 인증
        emailCert() {
            if (!this.eId) {
                alert('아이디를 입력해주세요.');
                return
            }
            if (!this.eName) {
                alert('이름을 입력해주세요.');
                return
            }
            if (!this.email1 || !this.email2) {
                alert('이메일을 입력해주세요.');
                return
            }
            const email = `${this.email1}@${this.email2}`

            this.axios
                .get(this.api.member.findPassword, {
                    params: {
                        memId: this.eId,
                        memName: this.eName,
                        memEmail: email,
                        // hpCd: this.$store.state.hpInfoStore.hpCd
                    }
                })
                .then(res => {
                    const result = res.data;
                    if (res.data.status) {
                        this.$store.commit('setFind', {'이메일': result.email});
                        this.$router.push(`/member/pwResolution.do`);
                    } else {
                        alert(result.errMsg);
                    }
                })
        },
    },
}
</script>